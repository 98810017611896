import styled from "styled-components";

export const HomeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
`;

export const Description = styled.div`
    width: 35%;
    margin: 0 auto;
    padding: 20px;

    a {
        text-decoration: none;
        color: black;
        font-weight:bold;
    }

    @media (max-width: 800px) {
        width: 90%;
        padding-top: 50px;
        padding-left: 30px;
        text-align: center;
        line-height: 40px;
    }
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    
    h1 {
        margin: 0;
    }

    @media (max-width: 800px) {
        font-size: 12px; 
        
    }
`;

export const Image = styled.img`    
    src: ${props => props.src};
    width: 30%;
    height: 30%;
    border-radius: 50%;
    margin: 0 auto;

    @media (max-width: 800px) {
        border-radius: 35%;
        width: 300px;
        height: 320px;   
    }
`;

export const KofiSupport = styled.div`
    font-size: 20px;
    line-height: 1.8;

    img {
        width: 300px;
        height: 50px;
    }
`;