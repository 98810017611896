import React from 'react';
import { Link } from 'gatsby';

import HomeImage from '../Images/Home.jpg';
import Head from '../components/head';
import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import KoFiButton from '../Images/Ko-fi-Button.png';

import {
  HomeContainer,
  Description,
  Title,
  Image,
  KofiSupport
} from '../Styles/index.style';

const Home = () => {
  return (
    <Layout>
      <Head title="Home" />
      <HomeContainer>
        <Image src={HomeImage} alt=" "/>
        <Description>
          <Title>
            <h1>Hello World! I'm Shaden</h1>
            <h1>Front End Developer,</h1>
            <h1>and Content Creator</h1>
          </Title>
          <p>Learn more <Link to="/About">about </Link> me!</p>
          <SubscribeForm />
            {/* <KofiSupport>
              <p>If you want to support my work, you can buy me a coffee</p>
              <a href="https://ko-fi.com/shadencodes" target="_blank" rel="noopener noreferrer">
                <img src={KoFiButton}></img>
              </a>
            </KofiSupport> */}
        </Description>
      </HomeContainer> 
    </Layout>
  )
}

export default Home;